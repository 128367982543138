import React from 'react';
import buses from '../images/buses.png';
import llt from '../images/llt.png';
import bookshelf from '../images/bookshelf-june.png';

import { Icon } from 'react-icons-kit';
import { github, arrowUpRight2 } from 'react-icons-kit/icomoon';

export default class Projects extends React.Component {
  state = {
    skills: [
      'AWS Elastic Beanstalk',
      'AWS Polly',
      'AWS EC2',
      'AWS S3',
      'AWS SES',
      'ReactJS',
      'Node.js',
      'Express',
      'MySQL',
      'Passport',
      'SemanticUI',
      'Heroku',
      'MongoDB',
      'React Bootstrap',
      'Chart.js',
      'Quill',
      'Netlify',
      'socket.io/WebSockets',
    ],
    projects: [
      {
        title: `Bookshelf`,
        description: [
          `Created as a way to keep track of physical copies of books. A user can save a book to their shelf my manually entering the ISBN or by scanning the barcode on the back of a book. Users can also create households with one another, in which their books are shared and can be viewed by members of the same household. Books can also be viewed at a public shelf link that is created for each user. A user can mark books to visible on this shelf.`,
        ],
        url: 'http://bookshelf.mikegallagher.app/',
        github: 'https://github.com/mjgall/bookshelf',
        skills: ['Heroku', 'ReactJS', 'Node.js', 'Express', 'MySQL', 'Passport', 'TailwindCSS'],
        image: bookshelf,
        urlClean: 'bookshelf.mikegallagher.app',
      },
      {
        title: 'Language Learning Tool',
        description: [
          `Inspired by foreign-language immersion learning, this app helps students associate spoken language with images instead of memorization of translated vocabulary. Users create courses by drawing images and entering corresponding text, which is converted to spoken audio by Amazon Polly.`,
          `Users can enroll in their own or other user-created courses, where they take quiz-format lessons to match the spoken phrase with one of four images.`,
          `Features authentication using Google or a traditional email and password combination that can be reset if needed.`,
        ],
        url: 'https://llt.gllghr.io',
        github: 'https://github.com/mjgall/mike-bull-soft',
        skills: [
          'AWS Polly',
          'AWS EC2',
          'AWS Elastic Beanstalk',
          'AWS S3',
          'AWS SES',
          'ReactJS',
          'Node.js',
          'Express',
          'MySQL',
          'Passport',
          'SemanticUI',
        ],
        image: llt,
        urlClean: 'llt.gllghr.io',
      },
      {
        title: 'Metro Transit Bus Tracker',
        description: [
          `A handy utility loved by my friends, the tracker displays Metro Transit bus arrival times for your frequent stops and your current Metro Transit card balance. Utilizes the Google Oauth flow, the Metro Transit API, and page scraping to fetch additional information not available via the API.`,
        ],
        url: 'https://buses.mpls.app',
        github: 'https://github.com/mjgall/buses.mpls.app',
        skills: ['Heroku', 'ReactJS', 'Node.js', 'Express', 'MongoDB', 'Passport', 'SemanticUI'],
        image: buses,
        urlClean: 'buses.mpls.app',
      },
    ],
  };

  render = () => {
    return (
      <>
        <h1 id="projects">Web Dev Projects</h1>
        <div className="projects">
          {this.state.projects.map(project => {
            return (
              <div className="project">
                <h2>{project.title}</h2>
                <div className="project-details-container">
                  <div style={{ display: 'grid', gridTemplateRows: '50px 50px', gridGap: '1rem', padding: '1rem 0' }}>
                    <a className="button" rel="noopener noreferrer" target="_blank" href={project.github}>
                      <Icon size="1.5rem" icon={github} style={{ marginRight: '1rem' }} />{' '}
                      <span style={{ verticalAlign: 'middle' }}>GitHub</span>
                    </a>
                    <a className="button" rel="noopener noreferrer" target="_blank" href={project.url}>
                      <Icon size="1.5rem" icon={arrowUpRight2} style={{ marginRight: '1rem' }} />{' '}
                      <span style={{ verticalAlign: 'middle' }}>{project.urlClean}</span>
                    </a>
                  </div>
                  <ul className="project-skills" style={{ listStyleType: 'none' }}>
                    {project.skills.map(skill => (
                      <li>{skill}</li>
                    ))}
                  </ul>
                  <img
                    className="project-screenshot"
                    alt={`Screenshot of ${project.title}`}
                    src={project.image}
                    style={{ width: '100%' }}
                  ></img>
                  <div>
                    {project.description.map(paragraph => (
                      <p>{paragraph}</p>
                    ))}
                  </div>
                  {/* <div>{ project.description }</div> */}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };
}
